import { Box, Grid, Typography, Card, CardContent } from "@material-ui/core"
import React from "react"
import { Layout, SEO } from "../components"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  styledInfo: {
    color: "rgb(255, 193, 7)",
    fontSize: 25,
    fontWeight: "bolder",
  },
  simpleInfo: {
    marginLeft: "10px",
    marginTop: "4px",
  },
  styledDiv: {
    backgroundColor: "rgba(25, 25, 35, 0.92)",
    height: "80px",
    width: "93%",
    margin: "auto",
  },

  media: {
    height: 140,
  },
  card: {
    margin: 6,
    minHeight: "230px",
    background:
      "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
    color: "white",
    boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
    padding: "5px",
  },
  hr: {
    backgroundColor: "white",
  },
  question: {
    fontWeight: "bolder",
  },
  answer: {
    marginLeft: "10px",
    fontWeight: "200",
  },
})

const contact = () => {
  const classes = useStyles()

  const { services } = useStaticQuery(
    graphql`
      query MyAllServices {
        services: allContentfulServices {
          edges {
            node {
              id
              name
              description {
                data
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout title={"My Services"}>
      <SEO title="My Services" previewImage={{ width: 267 }} />
      <Box mt={2} ml={3} mr={3}>
        <Box mt={4}>
          <Typography variant="h5" component="h4">
            My Services
          </Typography>
          <Box mt={2} />
          <hr className={classes.hr} />
          <Box>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {services ? (
                services.edges.map(({ node: { id, name, description } }) => {
                  return (
                    <Grid key={id} item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography className={classes.title} gutterBottom>
                            SubType
                          </Typography>
                          <Typography variant="h5" component="h2">
                            {name}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" component="p">
                              <p
                                style={{ paddingTop: 10, paddingBottom: 7 }}
                                dangerouslySetInnerHTML={{
                                  __html: description.data,
                                }}
                              ></p>
                            </Typography>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })
              ) : (
                <p>No services</p>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default contact
